import Script from 'next/script'
import { Fragment } from 'react'

/* eslint-disable @next/next/no-before-interactive-script-outside-document */
const OneTrust = () => (
  <Fragment>
    <Script
      strategy='beforeInteractive'
      src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      data-domain-script='0a3a5f7c-c06e-4505-bff9-3c05cacebf6f'
    />
    <Script
      id='cookie-wrapper'
      strategy='beforeInteractive'
    >
      {'function OptanonWrapper() { }'}
    </Script>
  </Fragment>
)
/* eslint-enable @next/next/no-before-interactive-script-outside-document */

export default OneTrust
