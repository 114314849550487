import PropTypes from 'prop-types'
import { Fragment } from 'react'

import BaseStyles from '@thebeansgroup/ui_base_styles'

import OneTrust from '@scripts/one_trust'

const MyApp = ({ Component, pageProps }) => {
  return (
    <Fragment>
      <BaseStyles includeFonts={false} />
      <Component {...pageProps} />
      <OneTrust />
    </Fragment>
  )
}

MyApp.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.object.isRequired
}

export default MyApp
